/* import { extractQueryString, parseQueryString } from '@shein/common-function'
import { click_ranking_list_label } from 'public/src/pages/cart_v2/analysis/item/rankListTag.js' */

export function useRankLiskTagEvent() {

  const onClick = async ({ itemOpts: { data, event }, options: { index = 1 } = {}, analysis = {} }) => {
/*     const tag = data.value?.aggregateProductBusiness?.productTags?.find(item => item.type === 'rankList')
    const query = parseQueryString(extractQueryString(tag?.data?.rankList?.jumpLink))
    const params = JSON.parse(query?.data) */
  }

  const onReport = async ({ itemOpts: { data, event }, options: { index = 1 } = {}, analysis = {} }) => {

  }

  const onExpose = async ({ itemOpts: { data, event }, options: { index = 1 } = {}, analysis = {} }) => {

  }

  return {
    onClick,
    onReport,
    onExpose,
  }
}