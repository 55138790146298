import { daEventCenter } from 'public/src/services/eventCenter/index'

const getActTagAppTraceInfo = (data) => {
  return data?.product?.actTag?.appTraceInfo
}
export const getSaGoodsList = (data, { index, cartAbtInfo, scene, el }) => {
  const goodsId = data.product.goods_id
  const skuId = data.product.goods_sn
  const spuId = data.product.productRelationID
  const page = 1
  const operation = 1
  const recMark = ''
  const price = data.aggregateProductBusiness?.priceData?.unitPrice?.price?.usdAmount
  const originPrice = ['suggestV1', 'suggestV1Special'].includes( data.aggregateProductBusiness?.priceData?.priceMode) ?  data.product?.suggested_sale_price?.usdAmount || '' : data.product?.retailPrice?.usdAmount || ''

  const isImgHigherRightContent = () => {
    if (!el) {
      return false
    }
    // 有两个模板，一个主车，一个mini车
    const imgEl = el.querySelector('.bsc-cart-item-main__left') || el.querySelector('.bsc-cart-item-mini__left')
    const rightContentEl = el.querySelector?.('.bsc-cart-item-main__right') || el.querySelector?.('.bsc-cart-item-mini__right')
    return imgEl.offsetHeight > rightContentEl.offsetHeight
  }
  const goodsStatus = scene === 'cart' ? data.aggregateProductBusiness?.productRowBiData?.goodsStatus : 'sku_switch_unavailable'

  const otherMark = []
  const actTagAppTraceInfo = getActTagAppTraceInfo(data)
  if (actTagAppTraceInfo) {
    otherMark.push(actTagAppTraceInfo)
  }
  if (data.aggregateProductBusiness?.productImgLabel?.titleImgBiDate) {
    otherMark.push(data.aggregateProductBusiness.productImgLabel.titleImgBiDate)
  }
  if (data.aggregateProductBusiness?.productTags?.find?.(item => item.type == 'payMemberGift' && !item.style)) {
    otherMark.push('show_membergift_tag')
  }
  if (data.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'categoryVoucher') || null) {
    otherMark.push('show_voucher')
  }
  if (data.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'evoluShein') || null) {
    otherMark.push('show_evolushein')
  }
  otherMark.push(isImgHigherRightContent() ? 'goods_length_0' : 'goods_length_1')
  if (data.aggregateProductBusiness?.productRowBiData?.amountDiscounted) {
    otherMark.push(data.aggregateProductBusiness.productRowBiData.amountDiscounted)
  }
  if (data.blind_box_flag == 1) {
    otherMark.push('mysterygift')
  }
  if (data?.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'rankList')) {
    otherMark.push('ranking_list_label')
  }

  const attrStock = data.attr.stock
  const insurable = data.product.insurable

  let unuseCouponTips = 'unused_coupon_tip_0'
  if (data?.product?.have_tag == 1) {
    unuseCouponTips = `unused_coupon_tip_${
      cartAbtInfo?.['Cartnoteligibleforcoupons']?.param == 'on'
        ? 1
        : 2
    }`
  }
  const tspId = data.aggregateProductBusiness?.productImgLabel?.titleImgBiData
  const mallCode = data.mall_code
  return `${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|pri_${originPrice}\`${otherMark.join('|')}\`left_${attrStock || ''}${insurable == 1 ? '|insured_1' : ''}|${unuseCouponTips}${tspId ? `|show_icon_${tspId}` : ''}\`mall_${mallCode}\`|${goodsStatus}` // goods_list：商品列表 goods_id`skc`spu_id`坑位`页码`运营位置`流量标识rec_mark`置顶标识extra_mark`pri_在售价格｜pri_原价（美元，保留小数点两位）`其它标识`销售或促销属性`mall_mallcode`列表服务算法额外标识
}

export const expose_goods_list = (data, callback) => {
  const defaultData = {
    scene: 'cart',
    goods_list: '',
    label_id: 'all',
    traceid: '',
    abtest: '-',
    activity_from: 'goods_list',
    style: 'detail', // 点击商品跳转到详情页上报 detail，点击商品跳转打开弹窗上报 popup，不允许点击的商品暂时上报: detail(等产品确认) 
  }
  daEventCenter.triggerNotice({ daId: '1-7-3-6', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_goods_list = (data, callback) => {
  const defaultData = {
    item: data,
    index: '',
    tspId: data.aggregateProductBusiness?.productImgLabel?.titleImgBiData || '',
    behaviorText: getActTagAppTraceInfo(data),
    goodsNameBeforeLabelId: data.aggregateProductBusiness?.productImgLabel?.titleImgBiData || '',
  }
  daEventCenter.triggerNotice({ daId: '1-7-3-3', extraData: callback?.(defaultData) ?? defaultData })
}
