import { daEventCenter } from 'public/src/services/eventCenter/index'

export const expose_promotion_block = (data, callback) => {
  const defaultData = {
    promotion_id: '',
    promotion_tp_id: '',
    content_list: '',
    src_module: '',
    src_identifier: '',
    src_tab_page_id: '',
  }
  daEventCenter.triggerNotice({ daId: '1-7-3-15', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_promotion_block = (data, callback) => {
  const defaultData = {
    promotion_id: '',
    promotion_tp_id: '',
    content_list: '',
    src_module: '',
    src_identifier: '',
    src_tab_page_id: '',
  }
  daEventCenter.triggerNotice({ daId: '1-7-3-16', extraData: callback?.(defaultData) ?? defaultData })
}

export const expose_cartpromotionadd = (data, callback) => {
  const defaultData = {
    typeId: '',
    isFullPromotion: '',
    activity_id: '',
    goods_sn: data?.product?.goods_sn,
    is_member_gift: data?.isPayMemberGift || '0',
  }
  daEventCenter.triggerNotice({ daId: '1-7-5-1', extraData: callback?.(defaultData) ?? defaultData })
}

export const expose_flash_link = (data, callback) => {
  const defaultData = {
    flashTypes: []
  }
  daEventCenter.triggerNotice({ daId: '1-7-5-19', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_flash_link = (data, callback) => {
  const defaultData = {
    flashTypes: []
  }
  daEventCenter.triggerNotice({ daId: '1-7-5-20', extraData: callback?.(defaultData) ?? defaultData })
}

export const expose_insured_goods = (data, callback) => {
  const defaultData = [{
    insured_goods: data?.product?.goods_id,
  }]
  daEventCenter.triggerNotice({ daId: '1-7-5-21', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_cartpromotionadd = (data, callback) => {
  const defaultData = {
    typeId: '',
    isFullPromotion: 0,
    goods_sn: data?.product?.goods_sn,
    activity_id: '',
    is_member_gift: data?.isPayMemberGift || '0',
  }
  daEventCenter.triggerNotice({ daId: '1-7-5-2', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_membergift_tag = (data, callback) => {
  const defaultData = {
    goods_id: data?.product?.goods_id,
  }
  daEventCenter.triggerNotice({ daId: '1-7-3-58', extraData: callback?.(defaultData) ?? defaultData })
}

