import { useDiscountLimitTagEvent } from './useDiscountLimitTag.js'
import { useDiscountNormalTagEvent } from './useDiscountNormalTag.js'
import { useEstimatedPriceEvent } from './useEstimatedPrice.js'
import { useGoodsQtyEvent } from'./useGoodsQty.js'
import { useGoodsSaleAttrEvent } from './useGoodsSaleAttr.js'
import { usePromotionTagEvent } from './usePromotionTag.js'
import { useGoodsAddWishEvent } from './useGoodsAddWish.js'
import { useFindSimilar } from './useFindSimilar.js'
import { useGoodsDeleteButton } from './useGoodsDeleteButton.js'
import { useGoodsStoreEvent } from './useGoodsStore.js'
import { useBehaviorLabelEvent } from './useBehaviorLabel.js'
import { useRankLiskTagEvent } from './useRankLiskTag.js'

export function useItemEvents() {
  const discountLimitTagEvent = useDiscountLimitTagEvent()
  const discountNormalTagEvent = useDiscountNormalTagEvent()
  const estimatedPriceEvent = useEstimatedPriceEvent()
  const goodsQtyEvent = useGoodsQtyEvent()
  const goodsSaleAttrEvent = useGoodsSaleAttrEvent()
  const promotionTagEvent = usePromotionTagEvent()
  const goodsAddWishEvent = useGoodsAddWishEvent()
  const findSimilarEvent = useFindSimilar()
  const goodsDeleteButtonEvent = useGoodsDeleteButton()
  const goodsStoreEvent = useGoodsStoreEvent()
  const behaviorLabelEvent = useBehaviorLabelEvent()
  const rankLiskTagEvent = useRankLiskTagEvent()

  return {
    discountLimitTagEvent,
    discountNormalTagEvent,
    estimatedPriceEvent,
    goodsQtyEvent,
    goodsSaleAttrEvent,
    promotionTagEvent,
    goodsAddWishEvent,
    findSimilarEvent,
    goodsDeleteButtonEvent,
    goodsStoreEvent,
    behaviorLabelEvent,
    rankLiskTagEvent,
  }
}
